<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Field Master</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>

    <v-data-table
      :headers="headers"
      :items="field_list"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>
          <v-spacer></v-spacer>
          <span style="width: 20px"></span>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="indigo darken-4" dark class="mb-2" v-on="on"
                >Add Field</v-btn
              >
            </template>
            <v-card>
              <v-form v-model="valid">
                <v-card-title>
                  <v-row justify="space-between">
                    <span class="headline">{{ formTitle }}</span>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                  <!-- <span class="headline">{{ formTitle }}</span> -->
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Enter Field"
                          :rules="[(v) => !!v || 'required']"
                          persistent-hint
                          :hint="
                            editedIndex == -1
                              ? 'For example :: First Name, Middle Name, Last Name'
                              : 'For example :: First Name, Middle Name, Last Name'
                          "
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          :rules="[(v) => !!v || 'required']"
                          class="pt-2 pb-1"
                          v-model="editedItem.display_name"
                          label="Enter Display Name"
                          persistent-hint
                          :hint="
                            editedIndex == -1
                              ? 'For example : First Name....'
                              : ''
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <input
                          type="hidden"
                          v-model="editedItem.id"
                          name="id"
                        />
                        <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox
                          dense
                          v-model="editedItem.isactive"
                          color="success"
                          label="Active"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary darken-1"
                    dark
                    @click="save(item)"
                    :disabled="!valid"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- <template v-slot:item.isactive="{ item }">
            <span v-if="item.isactive">
              <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
            </span>
            <span v-else>
              <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
            </span>
          </template> -->
      <template v-slot:item.isactive="{ item }">
        <v-switch
          v-model="item.isactive"
          @change="activeswitch(item)"
          :labels="{ checked: 'On', unchecked: 'Off' }"
        ></v-switch>
      </template>
      <template v-slot:item.action="{ item }">
        <div>
          <v-avatar style="" size="25" class="edit-avatar">
            <v-icon class="edit-v-icon mr-2" @click="editItem(item)"
              >edit</v-icon
            >
          </v-avatar>
          <v-btn text>
            <v-icon color="red" @click="deletefunc(item.id)">
              mdi-delete</v-icon
            ></v-btn
          >
        </div>
      </template>
    </v-data-table>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    valid: false,
    item: "",
    overlay: false,
    errorMsg: {
      id: false,
      display_name: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    dialog: false,
    field_type_list: null,
    field_list: null,
    headers: [
      {
        text: "Sr No.",
        align: "left",
        sortable: false,
        value: "srno",
      },
      {
        text: "Field",
        align: "left",
        sortable: false,
        value: "name",
      },
      {
        text: "Display Name",
        value: "display_name",
      },
      {
        text: "Active",
        align: "left",
        sortable: true,
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],
    field_list: [],

    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      display_name: "",
      isactive: true,
    },
    defaultItem: {
      id: 0,
      name: "",
      display_name: "",
      isactive: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Field" : "Edit Field";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = false;
      };
    },
  },

  // created () {
  //   this.initialize()
  // },
  mounted() {
    this.onLoad();
  },
  methods: {
    initializeErrorMsgFalse() {
      this.errorMsg.id = false;
      this.errorMsg.display_name = false;
    },
    onLoad() {
      this.overlay = true;
      axios
        .post("/AdminMaster/addFieldMaster")
        .then((res) => {
          if (res.data.msg == "200") {
            if ((this.load = true)) {
              this.overlay = false;
              this.field_list = res.data.field_list;
            }
          } else {
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //window.console.log(error);
        })
        .finally(() => {
          "use strict";
          var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    editItem(item) {
      this.editedIndex = this.field_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        if (this.isValidated()) {
          axios
            .post("/AdminMaster/editFieldMaster", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                Object.assign(
                  this.field_list[this.editedIndex],
                  this.editedItem
                );

                this.showSnackbar("#4caf50", "Field Updated Successfully..."); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", "Field Already Present!!!"); // show snackbar on error
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
          this.close();
        }
      } else {
        axios
          .post("/AdminMaster/saveFieldMaster", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              this.field_list.push(this.editedItem);
              this.showSnackbar("#4caf50", "Field Added Successfully..."); // show snackbar on success
              this.onLoad();
            } else if (res.data.status.code == "NA") {
              this.showSnackbar("#b71c1c", "Field Already Present!!!"); // show snackbar on error
            }
            this.onLoad();
          })
          .catch((error) => {
            window.console.log(error);
          });

        this.close();
      }
    }, // end of save()

    isValidated() {
      if (this.editedItem.id && this.editedItem.display_name) {
        return true;
      } else {
        if (!this.editedItem.display_name) {
          this.errorMsg.display_name = true;
        }
        if (!this.editedItem.id) {
          this.errorMsg.id = true;
        }

        return false;
      }
    }, // .....end of isValidated()
    deletefunc(item) {
      const data = {
        deleteitem: item,
      };
      axios
        .post("/AdminMaster/deleteFieldMaster", data)
        .then((res) => {
          //console.log("a")
          if (res.data.msg == "200") {
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.onLoad();
          } else {
            //error
            this.showSnackbar("#b71c1c", res.data.msg);
          }
          this.onLoad();
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    activeswitch(item) {
      //console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      // console.log(item.id);

      axios
        .post("/AdminMaster/activefieldmaster", data)
        .then((res) => {
          //   console.log("res.data");
          //   console.log(res.data);
          if (res.data.msg == "200") {
            // console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
